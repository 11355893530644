<!-- 招生系统基础配置 -- 员工账号管理 -- 详情 -->
<template>
  <div class="form">
    <el-form :model="form" :rules="rules" ref="form" label-width="200rem"
             style="width: calc(100% - 300rem);margin-left:  22rem;margin-bottom: 100rem;min-height: calc(100vh - 412rem)">
      <el-form-item label="姓名" prop="name">
        <p>{{form.name}}</p>
      </el-form-item>
      <el-form-item label="性别" prop="gender">
        <p>{{form.gender_text}}</p>
      </el-form-item>
      <el-form-item label="手机号（账号）" prop="username">
        <p>{{form.username}}</p>
      </el-form-item>
      <el-form-item label="身份证号" prop="id_card">
        <p>{{form.id_card}}</p>
      </el-form-item>
      <el-form-item label="入职时间" prop="entry_time">
        <p>{{form.entry_time}}</p>
      </el-form-item>
      <el-form-item label="所属校区" prop="school">
        <p>{{form.school_text}}</p>
      </el-form-item>
      <el-form-item label="可招生校区" prop="admissible">
        <p>{{ form.admissible_text ? form.admissible_text.join('，') : '' }}</p>
      </el-form-item>
      <el-form-item label="岗位名称" prop="roles">
        <p>{{form.roles_text}}</p>
      </el-form-item>
      <el-form-item label="是否为部门负责人" prop="department_head">
        <p>{{form.department_head_text}}</p>
      </el-form-item>
      <el-form-item label="家长是否可见" prop="department_head">
        <p>{{form.is_parents_see === 0 ? '否' : '是' }}</p>
      </el-form-item>
      <el-form-item label="查看客户方式" prop="customer">
        <p>{{form.customer_text}}</p>
      </el-form-item>
      <el-form-item label="开户行" prop="bank">
        <p>{{form.bank_text}}</p>
      </el-form-item>
      <el-form-item label="开户省份" prop="province">
        <p>{{form.province_text}}</p>
      </el-form-item>
      <el-form-item label="开户城市" prop="city">
        <p>{{form.city_text}}</p>
      </el-form-item>
      <el-form-item label="开户区/县" prop="area">
        <p>{{form.area_text}}</p>
      </el-form-item>
      <el-form-item label="开户行" prop="bank">
        <p>{{form.bank_text}}</p>
      </el-form-item>
      <el-form-item label="开户网点" prop="bank_branch">
        <p>{{form.bank_branch}}</p>
      </el-form-item>
      <el-form-item label="银行行号（非必填）" prop="bank_code">
        <p>{{form.bank_code}}</p>
      </el-form-item>
      <div v-if="form.status === 0">
        <el-form-item label="禁用原因" prop="business_type">
          <p>{{form.type}}</p>
        </el-form-item>
        <el-form-item label="禁用备注" prop="remarks">
          <p>{{form.remarks}}</p>
        </el-form-item>
        <el-form-item label="禁用操作人" prop="operator">
          <p>{{form.operator}}</p>
        </el-form-item>
        <el-form-item label="禁用操作时间" prop="created_at">
          <p>{{form.created_at}}</p>
        </el-form-item>
      </div>
    </el-form>
    <div class="footer-button">
      <el-button type="primary" @click="$router.back()">返回</el-button>
    </div>
  </div>
</template>

<script>

export default {
	_config:{"route":{"path":"details","meta":{"title":"查看"}}},
  data() {
    return {
      accountConfig: {},
      form: {
        name: '',
        gender: '',
        username: '',
        password: '',
        id_card: '',
        school: '',
        roles: '',
        department_head: '',
        bank: '',
        bank_card: '',
        bank_code: '',
      },
      rules: {

      },
      schoolList: [],
      allSchool: false,
      scopeList: {}
    }
  },
  created() {
    this.getAccountConfig()
    this.getData()
  },
  methods: {
    getData(){
      this.$_register('/api/recruit/account/' + this.$route.query.id).then(res => {
        this.form = res.data.data
      })
    },

    //获取账户配置
    getAccountConfig() {
      this.$_register('/api/recruit/account/get-param').then(res => {
        this.accountConfig = res.data.data
      })
    },

  },
}
</script>

<style scoped>

::v-deep .el-form p{
  line-height: 40rem;
}

.form {
  padding-top: 70rem;
}

::v-deep .el-form .el-input {
  min-width: 250rem;
}
</style>
